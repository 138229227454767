<template>
  <div>
    <div class="card">
      <div class="card-body">
        <!-- BIG NUMBERS -->
        <div class="d-flex flex-column flex-lg-row px-4">
          <div class="col-lg-2 mb-2 mb-lg-0">
            <h2 class="highlighted-color1 font-size-regular">
              <span>{{ $t('impressions') }}</span>
            </h2>
            <div class="font-size-medium">
              {{ data.impressions | formatNumber }}
            </div>
          </div>
          <div class="col-lg-2 mb-2 mb-lg-0">
            <h2 class="highlighted-color1 font-size-regular">
              <span>{{ $t('clicks') }}</span>
            </h2>
            <div class="font-size-medium">
              {{ data.clicks | formatNumber }}
            </div>
          </div>
          <div class="col-lg-2 mb-2 mb-lg-0">
            <h2 class="highlighted-color1 font-size-regular">
              <span>{{ $t('ctr') }}</span>
            </h2>
            <div class="font-size-medium">
              {{ data.ctr | formatNumber }}%
            </div>
          </div>
          <div class="col-lg-3 mb-2 mb-lg-0" v-if="view !== 'lines'">
            <h2 class="highlighted-color1 font-size-regular">
              <span>{{ $t('investment') }}</span>
            </h2>
            <div class="font-size-medium">
              {{ data.amount / 100 | formatMoney(currency) }}
            </div>
          </div>
          <div class="col-lg-3">
            <h2 class="highlighted-color1 font-size-regular">
              <span>{{ $t('consumed') }}</span>
            </h2>
            <div class="font-size-medium">
              {{ data.spending / 100 | formatMoney(currency) }}
            </div>
          </div>
        </div> <!-- END BIG NUMBERS -->
        <!-- CHART -->
        <div class="d-none d-lg-block mt-4">
          <LineChartGenerator
            :chart-options="{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  type: 'linear',
                  display: true,
                  position: 'left'
                },
                y1: {
                  type: 'linear',
                  display: true,
                  position: 'right',

                  // grid line settings
                  grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                  }
                }
              }
            }"
            :chart-data="chartData"
            chart-id="chart"
            dataset-id-key="label"
            :height="350"
          />
        </div> <!-- END CHART -->
      </div>
    </div>
  </div>
</template>

<script>

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import moment from 'moment'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'CampaignSummary',
  props: ['data', 'view', 'currency'],
  components: {
    LineChartGenerator
  },
  computed: {
    chartData () {
      if (!this.data) return []

      return {
        labels: this.chartLabels,
        datasets: this.chartDatasets
      }
    },
    /**
     * Create datasets from summary entries
     */
    chartDatasets () {
      if (!this.data) return []
      const impressions = {
        label: this.$t('impressions'),
        backgroundColor: '#161711',
        borderColor: '#161711',
        data: this.data.history.map(item => {
          return item.impressions
        }),
        yAxisID: 'y'
      }

      const clicks = {
        label: this.$t('clicks'),
        backgroundColor: '#FED615',
        borderColor: '#FED615',
        data: this.data.history.map(item => {
          return item.clicks
        }),
        yAxisID: 'y1'
      }

      return [
        impressions,
        clicks
      ]
    },
    /**
     * Get labels from days of summary
     */
    chartLabels () {
      if (!this.data) return []

      return this.data.history.map(item => {
        if (this.$i18n.locale === 'en') return moment(item.day).format('MM/DD/YYYY')
        else return moment(item.day).format('DD/MM/YYYY')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
